<template>
  <div class="">
    <div class="container mt-5" v-if="isLoadingGettingQuizTranslation">
            <TranslationSkeleton v-for="index in 5" :key="index" class="mb-4" />
          </div>
   
  <div v-else-if="AllQuizQuestions.length"
            class="pb-1"
            style="    padding: 0px 2% 0px 1.5%;"
          >
            <div
              class=""
              style="
                border-radius: 12px;
                border: 1px solid #ededed;
                background: #fff;
                margin-bottom: 70px;
              "
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style="padding: 8px 0px; border-bottom: 1px solid #ededed"
              >
                <span
                  style="
                    color: #70798d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  "
                  >{{
                    selectedLang.langText
                  }}
                  Translation Settings</span
                >
              </div>
              <div
                class="d-flex justify-content-around align-items-center"
                style="padding: 8px 0px; border-bottom: 1px solid #ededed"
              >
                <div class="d-flex align-items-center" style="gap: 12px">
                  <lazy-image
                    style="height: 30px; width: 30px; border-radius: 50%"
                    :src="getEngLang.flag"
                  />
                  <span class="midRowContainer">English Text</span>
                </div>

                <div >
                  <span
                    class="midRowContainer"
                    style="text-transform: uppercase"
                  >
                    Quiz Translation
                  </span>
                </div>

                <div class="d-flex align-items-center" style="gap: 12px">
                  <img
                    style="height: 30px; width: 30px; border-radius: 50%"
                    :src="selectedLang.flag"
                  />
                  <span class="midRowContainer"
                    >{{
                      selectedLang.langText
                    }}
                    Translation</span
                  >
                </div>
              </div>

              <div class="" v-for="quiz,index in AllQuizQuestions" :key="quiz.id+index">
                <div class="d-flex align-items-center mt-3 translation-slide justify-content-center">
                  <div class="counter mx-3 ">Slide {{ index+1 }}</div>
                </div>
                <div
                  class="row px-3 my-3"
                 
                   v-for="(value, key) in quiz.translations" :key="key"
                >
                  <div class="col-12 col-md-5" v-if="key">
                    <div
                      style="
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #ededed;
                        background: #f9f9f9;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                      "
                    >
                      <span
                        style="
                          color: #667085;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 171.429% */
                        "
                        >{{ key }}</span
                      >
                    </div>
                  </div>
                  <div class="col-12 col-md-2" v-if="key">
                    <div
                      class="d-flex h-100 align-items-center justify-content-center"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.125 5.60768C18.125 5.44102 18.0583 5.28271 17.9417 5.16604L14.8417 2.06602C14.6 1.82435 14.2 1.82435 13.9583 2.06602C13.7167 2.30768 13.7167 2.70768 13.9583 2.94935L16 4.99102H2.5C2.15833 4.99102 1.875 5.27436 1.875 5.61602C1.875 5.95769 2.15833 6.24102 2.5 6.24102H15.9917L13.9583 8.27436C13.7167 8.516 13.7167 8.916 13.9583 9.15767C14.0833 9.28267 14.2417 9.34108 14.4 9.34108C14.5583 9.34108 14.7167 9.28267 14.8417 9.15767L17.9333 6.06606C17.9917 6.00772 18.0417 5.93272 18.075 5.84938C18.075 5.84105 18.075 5.83269 18.0833 5.82436C18.1083 5.74936 18.125 5.68268 18.125 5.60768Z"
                          fill="#667085"
                        />
                        <path
                          d="M17.5 13.7653H4.00833L6.04167 11.7319C6.28333 11.4903 6.28333 11.0903 6.04167 10.8486C5.8 10.6069 5.4 10.6069 5.15833 10.8486L2.06665 13.9402C2.00832 13.9985 1.95833 14.0736 1.925 14.1569C1.925 14.1653 1.92499 14.1736 1.91666 14.1819C1.89166 14.2486 1.875 14.3236 1.875 14.3986C1.875 14.5653 1.94164 14.7236 2.05831 14.8403L5.15833 17.9403C5.28333 18.0653 5.44164 18.1236 5.59998 18.1236C5.75831 18.1236 5.91667 18.0653 6.04167 17.9403C6.28333 17.6986 6.28333 17.2985 6.04167 17.0568L3.99999 15.0153H17.5C17.8417 15.0153 18.125 14.7319 18.125 14.3903C18.125 14.0486 17.8417 13.7653 17.5 13.7653Z"
                          fill="#667085"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="col-12 col-md-5" v-if="key">
                    <!-- <div class="quizell-type">
                      <input
                        type="text"
                        v-model="translate.convertedText"
                        class="w-100 quizell-form-control px-2"
                      />
                    </div> -->
                    <div
                      style="
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #ededed;
                        background: #fff;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                      "
                    >
                      <input
                        type="text"
                       v-model="quiz.translations[key]"
                        class="w-100 converterTextInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
          </div>
          <EmptyQuizTranslation :description="`Quickly translate your quiz into ${selectedLang.langText} language to connect with a larger, international audience.`" v-else :isLoading="isLoadingAddFirstTimeQuizTranslation" @click="addOrResetTranslationFirstTime()"></EmptyQuizTranslation>
          <div
          v-if="AllQuizQuestions.length>0"
        class="d-flex justify-content-end"
        style="
          padding: 12px 24px;
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
          position: fixed;
          bottom: 0px;
          width: calc(100% - 20rem);
        "
      >
        <Button :isLoading="isLoadingSaveQuiz"  @click="saveQuizTranslations" >
          <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z"
              fill="white"
            />
          </svg>
          <span>Save Changes</span>
        </Button>
      </div>
        </div>
</template>

<script>
import Button from '../../../Dashboard/Layout/Button.vue';
import axios from 'axios';
import TranslationSkeleton from '../TranslationSkeleton.vue';
import EmptyQuizTranslation from './EmptyQuizTranslation.vue';
export default {
  data(){
    return{
      isLoadingGettingQuizTranslation:false,
      isLoadingAddFirstTimeQuizTranslation:false,
      isLoadingSaveQuiz:false,
      AllQuizQuestions:[],
      
    }
  },
  components:{
    EmptyQuizTranslation,
    TranslationSkeleton,
    Button
  },
  watch:{
    selectedLang(){
      this.getQuizTranslation()
    }
  },
    props:{
        selectedLang:Object,
        getEngLang:Object
    },
    methods:{
     async saveQuizTranslations(){
      this.isLoadingSaveQuiz=true
        try{
          const response = await axios.post('/quiz/translation/save',{translations:this.AllQuizQuestions,quizId:this.selectedLang.quiz_id})
          if(response.status==200)
          this.getQuizTranslation();
        }
        catch(e){
          this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          throw e
        }
        finally{
          this.isLoadingSaveQuiz=false
        }
      },

      
      async   getQuizTranslation(){
        this.isLoadingGettingQuizTranslation=true
        try{
          const response = await axios.get('/quiz/translation',{params:{language_id:this.selectedLang.id,quizId:this.selectedLang.quiz_id}})
          this.AllQuizQuestions=response.data.data.translations
        
        }
        catch(e){
          this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          throw e
        }
        finally{
          this.isLoadingGettingQuizTranslation=false
        }
        },
        async addOrResetTranslationFirstTime(id=null){
          const language_id=id||this.selectedLang.id
          this.isLoadingAddFirstTimeQuizTranslation=true;
          try{
            const response = await axios.post('/quiz/google-translate',{quizId:this.selectedLang.quiz_id,language_id:language_id});
            response.status
            this.$toasted.show("Quiz Translated Successfully ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
            this.getQuizTranslation();
          }
          catch(e){
            this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
           
            throw e
          }
          finally{
            this.isLoadingAddFirstTimeQuizTranslation=false;
          }
        }
    },
    mounted(){
      this.getQuizTranslation()
    }
}
</script>

<style scoped>
.active-lang {
  border: 1px solid #0397cc !important;
  color: #0397cc !important;
  background: rgba(230, 245, 250, 1);
}
.defaults-btn {
  padding: 8px 8px 8px 12px;
  gap: 12px;
  border-radius: 6px;

  border: 1px solid rgba(237, 237, 237, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(102, 112, 133, 1);
}

.converterTextInput,
.converterTextInput:focus {
  background: transparent;
  outline: none;
  border: 0;
  color: #667085;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}
.midRowContainer {
  color: #1f1f28;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.translation-slide .counter{
  background-color: #F9F9F9;
  color: #73738D;
  font-size: 14px;
  font-weight: 500;
  border-radius: 40px;
  padding: 2px 12px;
  text-wrap: nowrap;
}
.translation-slide::after,.translation-slide::before{
  content: '';
  height: 1px;
  width: 100%;
  background-color: #EAECF0;
  display: block;
}
</style>